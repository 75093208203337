.safety {
  width: 100%;
  height: 45px;
  border-bottom: 5px solid #f59125;

}

.headline {
  width: 150px;
  background-color: #21bba7;;
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: white;
  font-size: 18px
}

.modified {
  height: auto;
  width: 100%;
  margin-top: 15px;
  background: white;
  display: inline-block
}

.modified p {

  width: 100%;
  margin-left: 50px;
  margin-top: 10px;
  color: #858585;
  font-size: 14px
}

progress {
  width: 100px;
  height: 16px;
  border: 1px solid gainsboro;
  background-color: #fbfbfb;
  color: #0064B4; /*IE10*/
}

.xinx {
  margin-top: 60px
}

.father {
  width: 100%;
  height: auto;
  float: left
}

progress::-moz-progress-bar {
  background: #5e9909
}

progress::-webkit-progress-bar {
  background: white
}

progress::-webkit-progress-value {
  background: #5e9909
}

.Account {

  margin: 55px auto auto auto;
  height: 580px;
}

.A1 {
  min-width: 500px;
  height: 40px;
  border: 1px solid rgba(216, 216, 216, 1);
  background: rgba(242, 242, 242, 1);
  margin-left: 20px;
  margin-top: 20px;
  line-height: 40px;
}

.A1 label {
  width: 50%;
  color: gray;
  font-weight: normal;
  text-align: center;
}

.A1 a {
  text-decoration: none;
  color: #65cab0;
  font-weight: normal
}

.A1 img {
  position: relative;
  margin: 0px 10px 0 10px;
  vertical-align: middle;
}

.set {
  float: right;
  margin-right: 10px
}

.alter_pwd a {
  color: rgba(31, 190, 169, 1);
}

.alter_pwd {
  padding-top: 10px;;
  background-color: white;
  border: 1px silver solid;
}

.alter_pwd img {
  position: relative;
  margin: 0px 10px 0 10px;
  vertical-align: middle;
}

/*.alter_pwd table{border: 1px solid crimson;width: 70%}*/
.risk {
  width: 60%;
  overflow: hidden
}

.PIW {
  width: 30%;
  height: 5px;
  margin-top: 10px;
  border: 1px solid gainsboro;
  float: left;
  margin-left: 5px
}

.hint {
  width: 30%;
  text-align: right;
  vertical-align: middle
}

.hint1 {
  width: 70%;
  margin: auto;
}

.mytable {
  margin-left: 20px;
  margin-top: 20px;
  border: 0;
}

.mytable td {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 2em;
}

.hint1 input[type=text] {
  line-height: 25px;
  height: 25px;
  width: 70%;
}

.mima {
  display: none;
  float: left;
  overflow: hidden;
  margin-left: 20px;
  margin-top: 20px;

}

.savehold {
  text-align: center;
}

.savehold .save {
  display: block;
  background-color: #fda105;
  margin-left: 200px;
  width: 50px;
  height: 20px;
  border-radius: 3px;
  line-height: 20px;
  color: white;
  font-weight: normal;
  cursor: pointer;

}

.state {
  width: 30%;
  float: left;
  margin-left: 20px
}

.pwd_box table {
  float: left;
  width: 62%;

}

.pwd_box {
  overflow: hidden;
  width: 100%
}

.state {
  margin-top: 20px
}

.state li {
  font-weight: normal;
  list-style: none;
  font-size: 12px;
  line-height: 20px
}

.state li a {
  text-decoration: none;
}

.check {
  position: relative;
  padding-left: 20%;
  font-size: 0.7em;

}
